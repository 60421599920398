<template>
  <div>
    <base-page-heading title="Adauga produs solicitat" subtitle="Adaugare produs solicitate de catre un partener">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Produsele solicitate</b-breadcrumb-item>
          <b-breadcrumb-item active>Adaugare</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row class="row-deck">
        <b-col xl="12">
          <base-block>
            <b-form @submit.stop.prevent="onAddFutureOrderSubmit">
              <b-row>
                <b-col xl="12">
                  <base-block title="Introduce datele solicitarii" header-bg content-full>
                    <b-row>
                      <b-col xl="4" offset-xl="2">
                        <b-form-group label-for="category">
                          <b-form-select id="category" size="lg" class="form-control-alt" :options="categoryChoices" plain name="category" v-model="$v.addFutureOrderForm.category.$model" :state="!$v.addFutureOrderForm.category.$error && (!errors.addFutureOrderForm.category) && null" aria-describedby="category-feedback" @blur="removeServerErrors('addFutureOrderForm')" @input="removeServerErrors('addFutureOrderForm')"></b-form-select>
                          <b-form-invalid-feedback v-if="!$v.addFutureOrderForm.category.required" id="category-feedback">
                            Campul este obligatoriu!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.addFutureOrderForm.category" v-text="errors.addFutureOrderForm.category" id="product-feedback"></b-form-invalid-feedback>
                        </b-form-group>
                        <div class="form-group">
                          <b-form-input type="text" size="lg" class="form-control-alt" id="product" name="product" placeholder="Produs" v-model="$v.addFutureOrderForm.product.$model" :state="!$v.addFutureOrderForm.product.$error && (!errors.addFutureOrderForm.product) && null" aria-describedby="product-feedback" @blur="removeServerErrors('addFutureOrderForm')" @input="removeServerErrors('addFutureOrderForm')"></b-form-input>
                          <b-form-invalid-feedback v-if="!$v.addFutureOrderForm.product.required" id="product-feedback">
                            Campul este obligatoriu!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="!$v.addFutureOrderForm.product.minLength" id="product-feedback">
                            Campul trebuie sa aiba minimum 3 caractere!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.addFutureOrderForm.product" v-text="errors.addFutureOrderForm.product" id="product-feedback"></b-form-invalid-feedback>
                        </div>
                        <b-form-group label-for="type">
                          <b-form-select id="type" size="lg" class="form-control-alt" :options="typeChoices" plain name="type" v-model="$v.addFutureOrderForm.type.$model" :state="!$v.addFutureOrderForm.type.$error && (!errors.addFutureOrderForm.type) && null" aria-describedby="type-feedback" @blur="removeServerErrors('addFutureOrderForm')" @input="removeServerErrors('addFutureOrderForm')"></b-form-select>
                          <b-form-invalid-feedback v-if="!$v.addFutureOrderForm.type.required" id="type-feedback">
                            Campul este obligatoriu!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.addFutureOrderForm.type" v-text="errors.addFutureOrderForm.type" id="type-feedback"></b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col xl="4">
                        <b-form-group label-for="observations" class="mb-3">
                          <b-textarea rows="4" name="observations" id="observations" class="form-control-alt border" placeholder="Introduceti mentiuni aici..." v-model="$v.addFutureOrderForm.observations.$model" :state="!$v.addFutureOrderForm.observations.$error && (!errors.addFutureOrderForm.observations) && null" aria-describedby="observations-feedback" @blur="removeServerErrors('addFutureOrderForm')" @input="removeServerErrors('addFutureOrderForm')"></b-textarea>
                          <b-form-invalid-feedback v-if="!$v.addFutureOrderForm.observations.required" id="observations-feedback">
                            Campul este obligatoriu!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.addFutureOrderForm.observations" v-text="errors.addFutureOrderForm.observations" id="observations-feedback"></b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label-for="term" class="mt-2">
                          <flat-pickr size="lg" id="term" class="form-control form-control-alt" placeholder="Termen de livrare" v-model="$v.addFutureOrderForm.term.$model" :config="datepickerConfig"></flat-pickr>
                        </b-form-group>
                        <div>
                          <b-row>
                            <b-col xl="4" offset-xl="8">
                              <b-button type="submit" variant="primary" block>
                                <i class="fa fa-fw fa-plus-circle mr-1"></i> Adauga
                              </b-button>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                  </base-block>
                </b-col>
              </b-row>
            </b-form>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<style lang="scss">
// Flatpickr + Custom overrides
@import '~flatpickr/dist/flatpickr.css';
@import './src/assets/scss/vendor/flatpickr';
</style>

<script>
import {validationMixin} from "vuelidate";
import {minLength, required} from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import {CREATE_FUTURE_ORDER, GET_DATA_FOR_ADD} from "../../../constants/future-order-graphql";
import {fleshErrorMessage, handleFormValidation} from "../../../error/server-error";

export default {
  name: "AddFutureOrder",
  mixins: [validationMixin],
  components: {flatPickr},
  data () {
    return {
      datepickerConfig: {
        minDate: 'today',
      },
      typeChoices: [],
      statusChoices: [],
      categoryChoices: [],
      addFutureOrderForm: {
        category: null,
        product: null,
        type: null,
        observations: null,
        term: null,
      },
      errors: {
        addFutureOrderForm: {},
      },
    }
  },
  validations: {
    addFutureOrderForm: {
      category: {
        required
      },
      product: {
        required,
        minLength: minLength(3)
      },
      type: {
        required
      },
      observations: {},
      term: {}
    }
  },
  methods: {
    onAddFutureOrderSubmit() {
      this.errors.addFutureOrderForm = {};
      this.$v.addFutureOrderForm.$touch()
      if (this.$v.addFutureOrderForm.$anyError) {
        return;
      }
      this.$apollo.mutate({
        mutation: CREATE_FUTURE_ORDER,
        variables: {
          'categoryId': this.$v.addFutureOrderForm.category.$model,
          'product': this.$v.addFutureOrderForm.product.$model,
          'type': this.$v.addFutureOrderForm.type.$model,
          'status': 1,
          'comment': this.$v.addFutureOrderForm.observations.$model && this.$v.addFutureOrderForm.observations.$model !== "" ? this.$v.addFutureOrderForm.observations.$model : null,
          'term': this.$v.addFutureOrderForm.term.$model && this.$v.addFutureOrderForm.term.$model !== "" ? this.$v.addFutureOrderForm.term.$model : null,
        }
      }).then(() => {
        this.flashMessage.info({message: "Solicitare adaugat cu succes!"});
        this.$router
            .push('/requested-missing-product/list')
            .catch(error => {
              error = {};
              fleshErrorMessage(error, this);
            });
      }).catch((error) => {
        let form = this.$v.addFutureOrderForm;
        this.errors.addFutureOrderForm = handleFormValidation(error, form);
        fleshErrorMessage(error, this);
      })
    },
    removeServerErrors(form) {
      this.errors[form] = {};
    }
  },
  apollo: {
    meUser: {
      query: GET_DATA_FOR_ADD,
      result(result) {
        this.statusChoices = result.data.meUser.futureOrderData.statusChoices;
        this.typeChoices = result.data.meUser.futureOrderData.typeChoices;
        let mainCategories = [];
        mainCategories.push({value: null, text: 'Selecteaza categorie'});
        result.data.productMainCategories.forEach(function (item) {
          let tmp = {};
          tmp.label = item.name;
          tmp.options = [];
          item.subCategories.forEach(function (subCategory) {
            tmp.options.push({value: subCategory._id, text: subCategory.name})
          });
          mainCategories.push(tmp);
        });
        this.categoryChoices = mainCategories;
        this.loaded = true;
      }
    }
  },
}
</script>